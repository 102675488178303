import { createApp } from "/node_modules/.vite/deps/vue.js?v=aa49b40d"
import { createPinia } from "/node_modules/.vite/deps/pinia.js?v=aa49b40d"
import App from "/src/App.vue"
import router from "/src/router/index.js"

import "/node_modules/vuetify/lib/styles/main.css"
import { createVuetify } from "/node_modules/.vite/deps/vuetify.js?v=aa49b40d"
import * as components from "/node_modules/.vite/deps/vuetify_components.js?v=aa49b40d"
import * as directives from "/node_modules/.vite/deps/vuetify_directives.js?v=aa49b40d"
import "/node_modules/@mdi/font/css/materialdesignicons.css"
import { aliases, mdi } from "/node_modules/.vite/deps/vuetify_iconsets_mdi.js?v=aa49b40d"

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
})

const app = createApp(App)
app.use(createPinia())
app.use(router)
app.use(vuetify)
app.mount('#app')